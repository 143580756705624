@import "./variables.scss";

.selling-points-wrapper {
  padding: 0 1.5rem;
  margin-bottom: 2rem;

  .selling-points {
    .selling-point {
      display: grid;
      grid-template-columns: 1.3125rem auto;
      grid-column-gap: 1rem;
      margin-bottom: 1.5rem;

      .icon {
        width: 100%;
        height: 100%;
        max-height: 1.625rem;
        transform: translateY(-3px);
      }

      .text-wrapper {
        h3 {
          margin: 0 0 0.375rem 0;
        }

        .description {
          font-size: $font-size-x-small;
        }
      }
    }
  }
}
