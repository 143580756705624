.processing {
  width: 50%;
  max-width: 20rem;
  text-align: center;
  margin: 10rem auto 0;

  h1 {
    margin: 0;
  }

  img {
    margin: 0 auto;
    width: 5rem;
  }
}
