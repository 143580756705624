@import "../styles/variables";

.adyen-credit-card-box {
  margin: 2rem $page-side-padding 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.adyen-checkout__label__text {
  font-size: $font-size-xx-small !important;
  font-weight: $font-weight-medium !important;
  color: $color-text-primary !important;
  margin: 0 0 0.4rem !important;
}

.adyen-checkout__input {
  height: 2.5rem !important;
  box-sizing: border-box !important;
  border: 0.03125rem solid $color-form-border !important;
  border-radius: 0.125rem !important;
  padding: 0 2.5rem 0 0.75rem !important;
  margin: 0 0 0.8rem !important;
}

.adyen-checkout__card__cardNumber__brandIcon {
  display: none;
}

.adyen-checkout__input--focus {
  box-shadow: none !important;
}

.adyen-checkout__input--valid {
  background: url(../assets/icons/checkmark-white.svg) !important;
  background-size: 1.5rem !important;
  background-repeat: no-repeat !important;
  background-position: center right 0.5rem !important;
}

.adyen-checkout__icon {
  display: none;
}
