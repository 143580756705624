@import "../styles/variables";

.price-info {
  display: flex;
  justify-content: space-between;
  height: auto;
  background-color: $color-gray-bright;
  padding: 0 $page-side-padding;
  margin-top: 1.5rem;
  border-top: 0.0125rem solid $color-border-gray-light;
  border-bottom: 0.0125rem solid $color-border-gray-light;

  h3 {
    color: $color-text-gray-dark;
    font-weight: $font-weight-demi-bold;
    text-transform: none;

    span {
      font-family: "SF Pro Display", sans-serif;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
    }
  }

  .title {
    left: 0;
  }
  .icon,
  .amount {
    float: right;
    img {
      height: 2.5rem;
      margin-right: 2.25em;
    }
  }

  .icon {
    display: flex;
    align-items: center;
  }
}
