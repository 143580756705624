@import "../styles/variables";

.user-form-wrapper {
  margin: 2rem $page-side-padding 0;
  border-bottom: 0.0625rem solid $color-border-gray-light;

  form {
    margin-bottom: 1.5rem;
  }

  label {
    display: block;
    font-size: $font-size-xx-small;
    font-weight: $font-weight-medium;
    margin: 0 0 0.4rem;
  }

  input {
    height: 2.5rem;
    width: 100%;
    box-sizing: border-box;
    border: 0.03125rem solid $color-form-border;
    border-radius: 0.125rem;
    padding: 0 2.5rem 0 0.75rem;
    margin: 0 0 0.8rem;

    &:valid {
      background: url(../assets/icons/checkmark-white.svg);
      background-size: 1.5rem;
      background-repeat: no-repeat;
      background-position: center right 0.5rem;
    }

    &:invalid {
      background: url(../assets/icons/red-cross.svg);
      background-size: 1.08rem;
      background-repeat: no-repeat;
      background-position: center right 0.7rem;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-text-placeholder;
    }
  }

  /* Reset Select */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: transparent;
    background-image: none;
    flex: 1;
    padding: 0 0.5em;
    cursor: pointer;
    z-index: 10;
  }

  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }

  /* Custom Select */
  .select {
    position: relative;
    display: flex;
    width: 100%;
    height: 3em;
    line-height: 3;
    background: $color-white;
    overflow: hidden;
    border: 0.03125rem solid $color-form-border;
    border-radius: 0.125rem;
  }

  /* Arrow */
  .select::after {
    content: "\25BC";
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: $color-white;
    color: $color-form-details;
    border-left: 0.03125rem solid $color-form-border;
    border-radius: 0.125rem;
    cursor: pointer;
    z-index: 1;
  }
}
