@import "./variables";
@import "./media-queries";

#title-header {
  padding: 0 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  max-width: $width-max-page;
  margin: 0 auto;

  h2 {
    text-transform: uppercase;
    margin: 0;
  }

  &.purple {
    background: $color-purple;

    h2 {
      color: $color-text-white;
    }
  }
}
