@import "../styles/variables";

.web-view-container {
  .title-wrapper {
    margin-top: 1.5rem;

    h1,
    h2,
    p {
      text-align: center;
    }

    p {
      margin: 0 1.75rem;
      font-size: $font-size-small;
    }
  }

  .logo-wrapper {
    margin: 1.5rem 0;
    text-align: center;
  }

  .faq-wrapper {
    margin-top: 1.5rem;
  }

  hr {
    max-width: $width-max-page;
    border: none;
    border-bottom: 0.03125rem solid $color-hr-gray-bright;
  }

  .features-wrapper {
    margin-bottom: 1.5rem;
  }

  .purchase-wrapper {
    max-width: $width-max-page;
    margin: 1.5rem auto 1.5rem;
    text-align: center;
  }

  .row {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: $width-max-page;
    padding: 0 $page-side-padding;
  }

  #order-view-content {
    .row {
      padding: 0;
    }
  }
}
