@import "./variables";

.confirmation-wrapper {
  text-align: center;
  img {
    display: block;
    margin: 3.125rem auto 1rem;
    height: 5.875rem;
  }

  h1 {
    margin: 0 0 1.25rem;
    span {
      display: table;
      margin: 0 auto;
    }
  }

  p {
    font-weight: $font-weight-medium;
    margin: 0 auto;
    max-width: 15rem;
  }

  .button {
    margin-top: 1.75rem;
    width: 17rem;
  }
}
