@import "../styles/variables";

.feature-list-wrapper {
  .title {
    color: $color-text-secondary;
    font-family: "SF Pro Display", sans-serif;
    margin: 1rem 0 0.5rem;
  }

  .feature {
    display: flex;
    align-items: center;
    padding: 0.4rem 0 0.4rem;
    border-bottom: 0.03125rem solid $color-hr-gray-bright;

    .icon-wrapper {
      display: flex;
      align-items: center;
      height: 2rem;
      width: 2rem;

      .icon {
        height: 0.9375rem;
      }
    }

    .text-wrapper {
      display: block;
      height: auto;

      .sub-text {
        font-size: $font-size-x-small;
        color: $color-text-secondary;
        margin-top: 0.25rem;
      }
    }
  }

  .feature:last-child {
    border-bottom: none;
  }
}

#order-view-content {
  .feature-list-wrapper {
    .title {
      background-color: $color-gray-bright;
      color: $color-text-gray-dark;
      font-family: "Pebble", sans-serif;
      font-weight: $font-weight-demi-bold;
      text-transform: none;
      font-size: $font-size-medium;
      padding: 1rem $page-side-padding;
      margin-top: 0;
      border-bottom: 0.03125rem solid $color-border-gray-light;
    }

    .feature-list {
      margin: 0 $page-side-padding;
      border-bottom: 0.03125rem solid $color-hr-gray-bright;
    }

    .feature {
      margin: 0 1rem;
      padding: 0.75em 0;

      .icon-wrapper {
        display: flex;
        align-items: center;
        height: 1rem;
        width: 1rem;

        .icon {
          height: 0.46875rem;
        }
      }
      .text-wrapper {
        width: 100%;

        .text {
          float: left;
        }

        .sub-text {
          float: right;
          font-size: $font-size-xx-small;
          margin-top: 0;
        }
      }
    }
  }
}
