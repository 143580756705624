@import "./variables";

/* Customize the label (the container) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 2.1875rem;
  cursor: pointer;
  font-size: $font-base;
  line-height: 1.5625rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 1.5rem 1.5rem 1.5rem;

  /* Customize link in label */
  a {
    text-decoration: none;
    color: $color-purple
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a background */
    &:checked ~ .checkmark {
      background-color: $color-checkbox-checked;

      /* Show the checkmark when checked */
      &:after {
        display: block;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5625rem;
    width: 1.5625rem;
    background-color: $color-checkbox-unchecked;
    border: 0.03125rem solid $color-checkbox-border;

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 0.5rem;
      top: 0.25rem;
      width: 0.3125rem;
      height: 0.625rem;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
