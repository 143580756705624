@import "../styles/variables";

#upgrade-view-content {
  position: relative;

  .page-title {
    margin: 1.5rem 0 2rem;
  }

  .purchase-wrapper {
    position: sticky;
    bottom: 0;
    background-color: $color-white;
    padding: 1.125rem 0 2rem;
    margin: 0 auto;
  }

  .disclaimer {
    margin-top: 0.25rem;
    p {
      color: $color-text-footer;
    }
  }

  .features-wrapper {
    .title-wrapper {
      margin-bottom: 1.5rem;
    }
  }

  .faq-wrapper {
    margin-top: 2rem;
  }
}
