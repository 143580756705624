@import "./variables.scss";

#order-view-content {
  .footer {
    max-width: $width-max-page;
    background-color: $color-gray-bright;
    font-family: "Helvetica Neue", sans-serif;
    font-size: $font-size-xxx-small;
    padding: 2rem 1.5rem 3rem;
    margin: 1.5rem auto 0;
    border-top: 0.03125rem solid $color-border-gray-light;
  }
}
