@import "../styles/variables";
$assetPath : "../assets/icons";

.faq-title {
    color: $color-text-gray-dark;
    text-align: center;
    padding: 0.85em 0;
    border-bottom: 0.03125rem solid $color-hr-gray-bright;

}

.Collapsible {
    border-bottom: 0.03125rem solid $color-hr-gray-bright;

    .sub-text {
        font-size: $font-size-small;
        color: $color-text-secondary;
        margin-bottom: 0.85em;
        padding-right: 2em;
    }

    .Collapsible__trigger {
        display: block;
        font-weight: $font-weight-regular;
        text-decoration: none;
        color: black;
        position: relative;
        border: 1px solid $color-white;
        padding-bottom: 1em;
        padding-top: 1em;
        padding-right: 2em;
        font-size: $font-size-small;
    }

    .Collapsible__trigger:after {
        content: url(#{$assetPath}/arrow.svg);
        position: absolute;
        right: 1em;
        top: 1em;
        display: block;
        transition: transform 100ms;
    }

    .Collapsible__trigger.is-open:after {
        transform: rotateZ(90deg);
    }

    ol {
        margin-top: -0.7em;
        padding-right: 2em;
    }

    ul {
        padding-right: 2em;
    }
}

.show-questions-wrapper {
    padding-bottom: 1em;
    padding-top: 1em;

    .show-questions-text {
        font-style: italic;
        text-decoration: underline;
        font-size: $font-size-medium;
    }
}
