@import "../styles/variables";

.call-support-wrapper {
  max-width: $width-max-page;
  margin: auto;
  text-align: center;
  background-color: $color-purple;
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;

  .call-support-title {
    color: $color-text-white;
    text-align: center;
    padding-top: 0.85rem 0;
  }

  .button {
    width: 13rem;
  }
}
