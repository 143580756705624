@import-normalize; // Bundled with CRA -- @TODO: Fix VSC SCSS lint warning

@import "./variables";
@import "./media-queries";

@font-face {
  font-family: "Pebble";
  src: url("../assets/fonts/Pebble_W_Rg.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../assets/fonts/SF-Pro-Display-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../assets/fonts/SF-Pro-Display-Bold.woff") format("woff");
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%heading-shared {
  font-family: "Pebble", sans-serif;
  color: $color-purple;
  text-transform: uppercase;
  font-weight: $font-weight-regular;
}

h1 {
  @extend %heading-shared;
  font-size: $font-size-x-large;
  line-height: $font-size-x-large * $line-height-multiplier-heading;
}

h2 {
  @extend %heading-shared;
  font-size: $font-size-large;
  line-height: $font-size-large * $line-height-multiplier-heading;
}

h3 {
  @extend %heading-shared;
  font-size: $font-size-medium;
  line-height: $font-size-medium * $line-height-multiplier-heading;
}

h4 {
  @extend %heading-shared;
  font-size: $font-size-medium;
  line-height: $font-size-medium * $line-height-multiplier-heading;
}

p {
  font-family: "SF Pro Display", sans-serif;
  color: $color-text-primary;
  font-size: $font-size-x-small;
  font-weight: $font-weight-medium;
  margin: 0;
  line-height: 1rem;
}

input,
textarea,
select {
  /* Prevents zooming for input fields */
  font-size: initial;
}

.button {
  display: inline-block;
  width: 19rem;
  height: 3.5rem;
  line-height: 3.5rem;
  border-radius: 1.75rem;
  text-decoration: none;
  padding: 0;
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
  letter-spacing: 0.5px;
  font-family: 'SF Pro Display', serif;
  border: none;
}

.button.green {
  background-color: $color-button-enabled;
  color: $color-text-white;
}

.button.purple {
  background-color: $color-purple;
  color: $color-text-white;
}

.button.gray {
  background-color: $color-button-disabled;
  color: $color-text-disabled;
}

.button.small {
  font-size: $font-size-x-small;
  height: 2.3125rem;
  line-height: 2.3125rem;
  width: 10rem;
}

.white-border {
  border: 0.1em;
  border-color: $color-white;
  border-style: solid;
}

.no-border {
  border: 0;
}

.inline-loading-spinner {
  display: inline-block;
  height: 2rem;
  margin-left: 0.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  &.small {
    font-size: $font-size-xxx-small;
  }

  &.italic {
    font-style: italic;
  }
}
